import React from "react"
import * as R from "ramda"
import Grid from "@material-ui/core/Grid"

import Link from "../navigation/Link"

//import Logo from "../../assets/icon/newsec-light.svg";
import Logo from "../../assets/img/footer-logo-242x70.png"

import LinkedInSvg from "../../assets/icon/linkedin-circle.svg"
import LinkedInHoverSvg from "../../assets/icon/linkedin-circle-hover.svg"
//import TwitterSvg from "../../assets/icon/twitter-circle.svg"
//import TwitterHoverSvg from "../../assets/icon/twitter-circle-hover.svg"
//import YouTubeSvg from "../../assets/icon/youtube-circle.svg"
//import YouTubeHoverSvg from "../../assets/icon/youtube-circle-hover.svg"

//import styles from './footer.module.scss'
import { makeStyles } from "@material-ui/core/styles"
import colors from "../theme/colors"

const useStyles = makeStyles(theme => ({
  root: {},
  logoContainer: {
    textAlign: "center",
  },
  logo: {
    height: "70px",
    width: "242.24"
  },
  someLinkContainer: {
    textAlign: "center",
  },
  someLink: {
    color: colors.base.white,
    textDecoration: "none",
    "&:hover": {
      color: colors.blue["0"],
      backgroundColor: "inherit",
    },
  },
  someIcon: {
    width: "1.75rem",
    height: "1.75rem",
  },
}))

const someHoverChange = (id, src) => {
  const elem = document.getElementById(id)
  if (elem) {
    elem.src = src
  }
}

const FooterDetails = ({ data }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item className={classes.logoContainer}>
        <Link to="https://newsec.fi" text="Newsec.fi">
          <img src={Logo} style={{height: "70px", width: "242.24", maxWidth: "242.24", maxHeight: "70px"}} alt="Newsec logo" />
        </Link>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.logoContainer}
      >
        {/* 
        <Grid item xs={4} className={classes.someLinkContainer}>
          {R.path(["site", "twitterUrl"], data) && (
            <a
              className={classes.someLink}
              href={R.path(["site", "twitterUrl"], data)}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                id="twitterSoMeIcon"
                onMouseOver={() =>
                  someHoverChange("twitterSoMeIcon", TwitterHoverSvg)
                }
                onMouseOut={() =>
                  someHoverChange("twitterSoMeIcon", TwitterSvg)
                }
                className={classes.someIcon}
                src={TwitterSvg}
                alt="Newsec Twitter Account"
              />
            </a>
          )}
        </Grid> 
        */}
        <Grid item xs={4} className={classes.someLinkContainer}>
          {R.path(["site", "linkedInUrl"], data) && (
            <a
              className={classes.someLink}
              href={R.path(["site", "linkedInUrl"], data)}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                id="linkedinSoMeIcon"
                onMouseOver={() =>
                  someHoverChange("linkedinSoMeIcon", LinkedInHoverSvg)
                }
                onMouseOut={() =>
                  someHoverChange("linkedinSoMeIcon", LinkedInSvg)
                }
                className={classes.someIcon}
                src={LinkedInSvg}
                alt="Newsec LinkedIn Account"
              />
            </a>
          )}
        </Grid>
        {/*
        <Grid item xs={4} className={classes.someLinkContainer}>
          {R.path(["site", "youTubeUrl"], data) && (
            <a
              className={classes.someLink}
              href={R.path(["site", "youTubeUrl"], data)}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                id="youtubeSoMeIcon"
                onMouseOver={() =>
                  someHoverChange("youtubeSoMeIcon", YouTubeHoverSvg)
                }
                onMouseOut={() =>
                  someHoverChange("youtubeSoMeIcon", YouTubeSvg)
                }
                className={classes.someIcon}
                src={YouTubeSvg}
                alt="Newsec YouTube Account"
              />
            </a>
          )}
        </Grid>
        */}
      </Grid>
    </Grid>
  )
}

export default FooterDetails
